/* You can add global styles to this file, and also import other style files */

/*
Default Style
============================*/

:root {
    --bodyFontFamily: "Open Sans", sans-serif;
    --headingFontFamily: "Catamaran", sans-serif;
    --mainColor: rgb(214, 186, 83); 
    --bodyColor: #7a7e9a;
    --headingColor: #293a5c;
    --whiteColor: #ffffff;
    --blackColor: #000000;
    --darkBlueColor: #123726;
    --boxShadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
    --transition: all 0.5s;
    --borderRadius: 4px;
}

body {
    padding: 0;
    margin: 0;
    font-size: 19px;
    line-height: 1.8;
    font-family: var(--bodyFontFamily);
    color: var(--bodyColor);
}
a {
	display: inline-block;
    transition: var(--transition);
    text-decoration: none;
	&:hover {
        text-decoration: none;
        color: var(--headingColor);
	}
	&:focus {
		text-decoration: none;
	}
}
button {
    outline: 0 !important; 
	box-shadow: none;
	border: none;
    &:focus {
        box-shadow: none;
    }
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    color: var(--headingColor);
	font-family: var(--headingFontFamily);
	font-weight: 700;
}
h3 {
    font-size: 21px;
    line-height: 1.5;
}
.d-table {
    width: 100%;
    height: 100%;
}
.d-table-cell {
    vertical-align: middle;
}
p {
    font-size: 15px;
	margin-bottom: 15px;
	&:last-child {
        margin-bottom: 0;
    }
} 
img {
    max-width: 100%;
    height: auto;
}
ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.form-control {
    height: 50px;
    color: var(--headingColor);
	border: 1px solid #e8e8e8;
	background-color: transparent;
	border-radius: 0;
	padding: 0;
	font-size: 15px;
	padding: 10px 20px;
	width: 100%;
	&::placeholder {
		color: #495057;
		font-size: 13px;
	}
	&:focus {
		color: var(--blackColor);
		background-color: transparent;
		box-shadow: unset;
		outline: 0;
		border: 1px solid var(--mainColor);
	}
}
.form-control:hover, .form-control {
    &:focus {
        box-shadow: unset;
    }
}
textarea {   
    &.form-control {
        height: auto;
		height: 250px;
    }
}
.ptb-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}
.pt-100 {
    padding-top: 100px;
}
.pb-100 {
    padding-bottom: 100px;
}
.pt-70 {
    padding-top: 70px;
}
.pb-70 {
    padding-bottom: 70px;
}
.mb-30 {
	margin-bottom: 30px;
}
.pl-30 {
	padding-left: 30px;
}
.pr-30 {
	padding-right: 30px;
}
.mt-30 {
	margin-top: 30px;
}
.mt-20 {
	margin-top: 20px;
}
.mb-20 {
	margin-bottom: 20px;
}
.mt-minus-70 {
	margin-top: -70px;
}
.mb-minus-70 {
	margin-bottom: -70px;
}
.ml-30 {
	margin-left: 30px;
}
.mr-30 {
	margin-right: 30px;
}
.bg-color {
    background: #f5faff;
}

@keyframes rotaed360 {
    0% {
        transform: rotateZ(0deg);
    }
    100% {
        transform: rotateZ(-360deg);
    }
}
@keyframes animationFramesOne {
    0% {
        transform: translate(0px, 0px) rotate(0deg);
    }
    20% {
        transform: translate(73px, -1px) rotate(36deg);
    }
    40% {
        transform: translate(141px, 72px) rotate(72deg);
    }
    60% {
        transform: translate(83px, 122px) rotate(108deg);
    }
    80% {
        transform: translate(-40px, 72px) rotate(144deg);
    }
    100% {
        transform: translate(0px, 0px) rotate(0deg);
    }
}

/*
Go Top Style*/
.go-top {
	position: fixed;
	cursor: pointer;
	top: 93%;
	right: -10%;
	background-color: var(--mainColor);
	z-index: 4;
	width: 40px;
	text-align: center;
	height: 42px;
	line-height: 42px;
	opacity: 0;
	visibility: hidden;
    transition: .9s;
	i {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		right: 0;
		margin: 0 auto;
		color: var(--whiteColor);
		transition: 0.5s;
		font-size: 20px;
		&:last-child {
			opacity: 0;
			visibility: hidden;
			top: 60%;
		}
	}
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		background-color: var(--mainColor);
		opacity: 0;
		visibility: hidden;
		transition: 0.5s;
	}
	&:hover {
		color: var(--whiteColor);
        background-color: var(--headingColor);
		&::before {
			opacity: 1;
			visibility: visible;
		}
		i {
			&:first-child {
				opacity: 0;
				top: 0;
				visibility: hidden;
			}
			&:last-child {
				opacity: 1;
				visibility: visible;
				top: 50%;
			}
		}
	}
	&:focus {
		color: var(--whiteColor);
		&::before {
			opacity: 1;
			visibility: visible;
		}
		i {
			&:first-child {
				opacity: 0;
				top: 0;
				visibility: hidden;
			}
			&:last-child {
				opacity: 1;
				visibility: visible;
				top: 50%;
			}
		}
	}
}
.go-top.active {
	transform: translateY(0);
	opacity: 1;
	visibility: visible;
	right: 3%;
    top: 93%;
}

/** Section tittle Css **/
.section-title {
    position: relative;
    max-width: 695px;
    margin: -8px auto 60px;
    text-align: center;
    span {
        color: var(--mainColor);
        font-size: 19px;
        position: relative;
        display: inline-block;
        margin-bottom: 8px;
    }
    h2 {
        font-size: 40px;
        text-transform: capitalize;
        color: var(--headingColor);
        margin-bottom: 20px;
    }
}

/** Box Btn Css **/
.box-btn {
    font-size: 16px;
    color: var(--whiteColor);
    padding: 15px 25px;
    line-height: 1;
    transition: var(--transition);
    text-transform: capitalize;
    position: relative;
    cursor: pointer;
    border-radius: 4px;
    text-align: center;
    overflow: hidden;
    z-index: 1;
    background-color: var(--mainColor);
    border: 1px solid var(--mainColor);
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 100%;
        width: 100%;
        height: 50%;
        background-color: var(--darkBlueColor);
        z-index: -1;
        transition: all 0.5s;
    }
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 100%;
        width: 100%;
        height: 50%;
        background-color: var(--darkBlueColor);
        z-index: -1;
        transition: all 0.5s;
    }
    &.border-btn {
        background-color: transparent;
        border: 1px solid var(--whiteColor);
    }
    &:hover {
        color: var(--whiteColor);
        &::before {
            left: 0;
        }
        &::after {
            right: 0;
        }
    }
}

/** Line Btn Css **/
.line-bnt {
    font-size: 14px;
    color: var(--headingColor);
    font-weight: 600;
    line-height: 1;
    &:hover {
        letter-spacing: 1px;
        color: var(--headingColor);
    }
}

/** Preloader Css **/
.loader-content {
    background: var(--darkBlueColor);
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 9999999;
    left: 0;
    top: 0;
    text-align: center;
}

.sk-folding-cube {
    margin: 20px auto;
    width: 40px;
    height: 40px;
    position: relative;
    transform: rotateZ(45deg);
    .sk-cube {
        float: left;
        width: 50%;
        height: 50%;
        position: relative;
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--whiteColor);
            animation: sk-foldCubeAngle 2.4s infinite linear both;
            transform-origin: 100% 100%;
            @keyframes sk-foldCubeAngle {
                0%,
                10% {
                    -webkit-transform: perspective(140px) rotateX(-180deg);
                    transform: perspective(140px) rotateX(-180deg);
                    opacity: 0;
                }
                25%,
                75% {
                    -webkit-transform: perspective(140px) rotateX(0deg);
                    transform: perspective(140px) rotateX(0deg);
                    opacity: 1;
                }
                90%,
                100% {
                    -webkit-transform: perspective(140px) rotateY(180deg);
                    transform: perspective(140px) rotateY(180deg);
                    opacity: 0;
                }
            }
        }
    }
    .sk-cube2 {
        transform: scale(1.1) rotateZ(90deg);
        &::before {
            animation-delay: 0.3s;
        }
    }
    .sk-cube3 {
        transform: scale(1.1) rotateZ(180deg);
        &::before {
            animation-delay: 0.6s;
        }
    }
    .sk-cube4 {
        transform: scale(1.1) rotateZ(270deg);
        &::before {
            animation-delay: 0.9s;
        }
    }
}


/*
header-2 Area Css
======================================================*/
.header-2 {
    background: var(--mainColor);
    &.three {
        background: #000b2f;
    }
    .header-content-right {
        .header-contact {
            li {
                display: inline-block;
                position: relative;
                padding-left: 25px;
                font-size: 16px;
                margin-right: 20px;
                a {
                    color: var(--whiteColor);
                    i {
                        position: absolute;
                        display: inline-block;
                        left: 0;
                        top: 4px;
                        color: var(--whiteColor);
                        font-size: 18px;
                    }
                    &:hover {
                        color: var(--whiteColor);
                    }
                }
            }
            &::before {
                display: none;
            }
        }
        .header-social {
            padding: 0;
            margin: 0;
            margin-left: 10px;
            li {
                display: inline-block;
                position: relative;
                padding-left: 10px;
                a {
                    font-size: 18px;
                    color: var(--whiteColor);
                    width: 35px;
                    height: 35px;
                    border: 1px solid var(--whiteColor);
                    border-radius: 50%;
                    line-height: 37px;
                    text-align: center;
                    transition: 0.3s;
                    transition: 0.3s;
                    &:hover {
                        background: var(--whiteColor);
                        color: #1e2a55;
                    }
                }
                &:first-child {
                    padding-left: 0;
                }
            }
        }
    }
}

/*
Navbar Area Css
======================================================*/
.navbar-area {
    background-color: transparent;
    transition: 0.3s;
    padding: 0;

    &.three {
        .main-nav {
            background-color: var(--darkBlueColor);
        }
    }
    .main-nav {
        background: #000;
        position: absolute !important;
        top: 10px !important;
        left: 0;
        padding-top: 15px;
        padding-bottom: 15px;
        width: 100%;
        z-index: 999;
        height: auto;

        &.sticky {
            position: fixed !important;
            top: 0 !important;
            width: 100%;
            left: 0;
            z-index: 999;
            animation: fadeInDown 500ms ease-in-out;
            transition: 0.3s;
            box-shadow: 0 1px 10px rgba(221, 221, 221, .2);
            background: var(--darkBlueColor) !important;
        }
    }
    .main-nav {
        background: transparent;
        transition: 0.4s;

        .navbar {
            transition: 0.4s;
            padding: {
                right: 0;
                top: 0;
                left: 0;
                bottom: 0;
            }
            ul {
                padding-left: 0;
                list-style-type: none;
                margin-bottom: 0;
            }
            .navbar-nav {
                margin: {
                    right: 0;
                }
                .nav-item {
                    position: relative;
                    padding: {
                        top: 15px;
                        bottom: 15px;
                        left: 0;
                        right: 0;
                    }
                    a {
                        font: {
                            size: 16px;
                            weight: 400;
                        }
                        color: var(--whiteColor);
                        text-transform: capitalize;
                        padding: {
                            left: 0;
                            right: 0;
                            top: 0;
                            bottom: 0;
                        }
                        margin: {
                            left: 0;
                            right: 30px;
                        }
                        &:hover,
                        &:focus,
                        &.active {
                            color: rgb(214, 186, 83);
                        }
                        i {
                            font-size: 16px;
                            position: relative;
                            top: 2px;
                            margin-left: 1px;
                        }
                    }
                    &:hover,
                    &.active {
                        a {
                            color: rgb(214, 186, 83);
                        }
                    }
                    .dropdown-menu {
                        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
                        background: var(--whiteColor);
                        position: absolute;
                        border: none;
                        top: 80px;
                        left: 0;
                        width: 240px;
                        z-index: 99;
                        display: block;
                        opacity: 0;
                        visibility: hidden;
                        border-radius: 0;
                        transition: all 0.3s ease-in-out;
                        &::before {
                            position: absolute;
                            width: 0;
                            height: 0;
                            content: "";
                            border-top: 0;
                            border-right: 8px solid transparent;
                            border-bottom: 8px solid var(--whiteColor);
                            border-left: 8px solid transparent;
                            top: -8px;
                            left: 20px;
                            z-index: -11;
                        }
                        padding: {
                            top: 0;
                            left: 0px;
                            right: 0px;
                            bottom: 0;
                        }
                        li {
                            padding: 0;
                            position: relative;
                            overflow: hidden;
                            &::before {
                                position: absolute;
                                content: '';
                                top: 0;
                                left: 0px;
                                width: 0;
                                height: 100px;
                                background: var(--mainColor);
                                transition: 0.5s all ease;
                                z-index: -1
                            }
                            a {
                                text-transform: capitalize;
                                padding: 10px 25px;
                                margin: 0;
                                color: #181818;
                                border-bottom: 1px dashed #eeeeee;
                                font: {
                                    size: 15px;
                                }
                                &:hover,
                                &:focus,
                                &.active {
                                    color: var(--mainColor);
                                }
                            }
                            &.active {
                                a {
                                    color: var(--mainColor);
                                }
                            }
                            &:hover {
                                a {
                                    color: var(--whiteColor);
                                }
                                .dropdown-menu {
                                    opacity: 1;
                                    visibility: visible;
                                    top: -15px;
                                }
                                &::before {
                                    width: 100%;
                                }
                            }
                            &:last-child {
                                a {
                                    border-bottom: none;
                                }
                            }
                        }
                    }
                    .dropdown-toggle {
                        &::after {
                            content: "+";
                            margin-left: .355em;
                            vertical-align: 0;
                            border-top: none;
                            border-right: none;
                            border-bottom: 0;
                            border-left: none;
                            transition: 0.3s all;
                        }
                    }
                    &:hover {
                        .dropdown-menu {
                            opacity: 1;
                            visibility: visible;
                            top: 50px;
                        }
                    }
                }
            }
        }
    }
    .box-btn {
        &::before,
        &::after {
            background: var(--whiteColor);
        }
        &:hover {
            color: #132460;
        }
    }
    .nav-btn {
        margin-left: 20px;
        position: relative;
        top: 3px;
    }
    .nav-right {
        display: inline-flex;
        width: 100%;
        position: relative;
        top: -3px;
        ::placeholder {
            color: #f5faf5;
            opacity: 1;
        }
        max-width: 16%;
        input.search {
            background: transparent;
            border: none;
            border-bottom: 1px solid #f5faff;
            position: relative;
            color: #f5faff;
            padding-left: 0;
            border-radius: 0;
            &:focus {
                box-shadow: none;
            }
        }
        button {
            background: none;
            border: none;
            color: #f5faff;
            font-size: 20px;
            position: absolute;
            top: 12px;
            right: 0;
            cursor: pointer;
            transition: var(--transition);
            z-index: 1;
            &:hover {
                color: var(--mainColor);
            }
        }
    }
}

/*
Slider 2 Area Css
======================================================*/
.slider-area-2 {
    position: relative;
    z-index: 1;
    .home-slider {
        .single-slider {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            border-bottom-left-radius: 90px;
            height: 100vh;
            position: relative;
            z-index: 1;
            border-bottom-right-radius: 90px;
            &::before {
                position: absolute;
                content: "";
                background: #000;
                opacity: 0.6;
                width: 100%;
                z-index: -1;
                height: 100%;
                top: 0;
                left: 0;
                border-bottom-left-radius: 90px;
                border-bottom-right-radius: 90px;
            }
            .slider-content {
                position: relative;
                max-width: 700px;
                margin: auto;
                h1 {
                    font-size: 60px;
                    color: var(--whiteColor);
                    margin-bottom: 20px;
                }
                p {
                    color: var(--whiteColor);
                    margin-bottom: 35px;
                }
            }
            .slider-btn {
                .box-btn {
                    margin-right: 20px;
                    border-radius: 50px;
                }
                .border-btn {
                    border-radius: 50px;
                    &:hover {
                        &::before {
                            background-color: var(--whiteColor);
                        }
                        &::after {
                            background-color: var(--whiteColor);
                        }
                        color: var(--mainColor);
                    }
                }
            }
        }
    }
    .owl-item {
        &.active {
            .slider-content {
                &.one {
                    h1 {
                        animation: 1s .2s fadeInUpBig both;
                    }
                    p {
                        animation: 1.5s .3s fadeInUpBig both;
                    }
                    a {
                        animation: 2s .4s fadeInUpBig both;
                    }
                }
            }
        }
    }
    .owl-nav {
        .owl-next {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            border: none;
            font-size: 16px;
            line-height: 50px;
            top: 50%;
            position: absolute;
            right: 1%;
            background: var(--mainColor);
            font-weight: bold;
            transition: 0.3s;
            color: var(--whiteColor);
            padding: 0 !important;
            transform: translateY(-50px);
            i {
                margin-left: 3px;
            }
            &:hover {
                background: var(--darkBlueColor);
                color: var(--whiteColor);
            }
        }
        .owl-prev {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            border: none;
            font-size: 16px;
            line-height: 50px;
            left: 1%;
            padding: 0 !important;
            top: 50%;
            position: absolute;
            color: var(--whiteColor);
            font-weight: bold;
            background: var(--mainColor);
            transition: 0.3s;
            transform: translateY(-50px);
            i {
                margin-right: 3px;
            }
            &:hover {
                background: var(--darkBlueColor);
                color: var(--whiteColor);
            }
        }
    }
    .single-slider-bg-1 {
        background: url(assets/images/home-bg-3.jpg)
    }
    .single-slider-bg-2 {
        background: url(assets/images/home-bg-4.jpg)
    }
    .single-slider-bg-3 {
        background: url(assets/images/home-bg-5.jpg)
    }
}

/*
Info Area Css
======================================================*/
.info-area {
    position: relative;
    .single-info {
        position: relative;
        transition: 0.3s;
        z-index: 1;
        margin-bottom: 30px;
        .info-img {
            img {
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                box-shadow: var(--boxShadow);
            }
        }
        .content {
            position: relative;
            max-width: 85%;
            margin: auto;
            background: var(--whiteColor);
            padding: 30px;
            text-align: center;
            margin-top: -40px;
            border-radius: 4px;
            transition: 0.3s;
            z-index: 1;
            box-shadow: var(--boxShadow);
            h3 {
                margin-bottom: 0;
                color: var(--darkBlueColor);
                font-size: 20px;
                i {
                    color: var(--mainColor);
                    margin-right: 10px;
                }
            }
            &::before {
                position: absolute;
                content: "";
                bottom: 100%;
                left: 0;
                right: 0;
                background-color: var(--darkBlueColor);
                overflow: hidden;
                width: 100%;
                height: 0;
                transition: .5s ease;
                z-index: -1;
            }
            .arrow {
                position: absolute;
                width: 35px;
                height: 35px;
                background: var(--mainColor);
                color: var(--whiteColor);
                border-radius: 50%;
                line-height: 35px;
                text-align: center;
                left: 50%;
                top: 110%;
                transform: translate(-50%, -50%);
                opacity: 0;
                transition: 0.3s;
                a {
                    color: var(--whiteColor);
                }
                i {
                    position: relative;
                    left: 1px;
                    font-weight: normal;
                }
            }
        }
        &:hover {
            .content {
                &::before {
                    bottom: 0;
                    height: 100%;
                }
                h3 {
                    color: var(--whiteColor);
                    i {
                        color: var(--whiteColor);
                    }
                }
                .arrow {
                    opacity: 1;
                    top: 95%;
                }
            }
        }
    }
}

/*
Service Area Css
======================================================*/
.single-service {
    position: relative;
    text-align: center;
    padding: 30px;
    margin-bottom: 30px;
    transition: var(--transition);
    border-radius: var(--borderRadius);
    z-index: 1;
    overflow: hidden;
    box-shadow: var(--boxShadow);
    .service-img {
        margin-bottom: 20px;
        img {
            width: 60px;
        }
    }
    .service-content {
        h3 {
            color: var(--darkBlueColor);
            margin-bottom: 5px;
        }
        p {
            margin-bottom: 10px;
        }
    }
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 100%;
        width: 100%;
        height: 100%;
        background-color: var(--darkBlueColor);
        z-index: -1;
        transition: all 0.5s;
    }
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 100%;
        width: 100%;
        height: 100%;
        background: url(assets/images/service/hover.png) no-repeat;
        z-index: -1;
        transition: all 0.5s;
    }
    &:hover {
        transform: translateY(-10px);
        h3 {
            color: var(--whiteColor);
        }
        p {
            color: #e9e9e9;
        }
        &::before {
            left: 0;
        }
        &::after {
            right: 0;
        }
        .service-content {
            .line-bnt {
                color: var(--whiteColor);
            }
        }
    }
}


/*
Home Company Area Css
======================================================*/
.home-company-area {
    padding-top: 130px;
    padding-bottom: 130px;
    .company-content {
        margin-top: -8px;
        margin-bottom: -8px;
        .company-tittle {
            span {
                color: var(--mainColor);
                font-size: 20px;
                margin-bottom: 8px;
                display: inline-block;
            }
            h2 {
                margin-bottom: 20px;
                font-size: 40px;
            }
        }
        .box-btn {
            margin-top: 35px;
        }
    }
    .company-img {
        position: relative;
        z-index: 1;
        &::before {
            position: absolute;
            content: "";
            width: 350px;
            height: 100px;
            background: rgb(214 178 83);
            z-index: -1;
            left: -30px;
            top: -30px;
            border-radius: 4px;
        }
        &::after {
            position: absolute;
            content: "";
            width: 350px;
            height: 100px;
            background: var(--darkBlueColor);
            z-index: -1;
            right: -30px;
            bottom: -30px;
            border-radius: 4px;
        }
    }
}



/*
Choose Area 2 Css
======================================================*/
.home2-choose-area {
    .single-choose {
        position: relative;
        transition: 0.3s;
        overflow: hidden;
        text-align: center;
        box-shadow: var(--boxShadow);
        margin-bottom: 30px;
        .icon {
            background: var(--whiteColor);
            border-radius: 4px;
            text-align: center;
            overflow: hidden;
            margin: auto;
            box-shadow: 2px 2px 20px 4px rgba(0, 0, 0, 0.07);
            padding: 10px 20px;
            i {
                font-size: 80px;
                color: var(--darkBlueColor);
                transition: 0.3s;
            }
        }
        h3 {
            text-align: center;
            color: var(--headingColor);
            margin-bottom: 0;
            padding: 10px 5px;
            font-size: 17px;
            background-color: #f1f1f1;
        }
        &:hover {
            transform: translateY(-10px);
            .icon {
                i {
                    color: var(--mainColor);
                }
            }
        }
    }
    .choose-c {
        position: relative;
        margin-top: 75px;
        .content {
            max-width: 600px;
            margin-left: 30px;
            h3 {
                font-size: 30px;
                color: var(--headingColor);
                margin-bottom: 15px;
                font-weight: 600;
            }
            p {
                color: var(--headingColor);
                margin-bottom: 10px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            .box-btn {
                border-radius: 50px;
                margin-top: 15px;
            }
        }
    }
}

/*
Process Area Css
======================================================*/
.home-process-area {
    background-color: var(--darkBlueColor);
    .section-title {
        span {
            color: var(--whiteColor);
        }
        h2 {
            color: var(--whiteColor);
        }
        p {
            color: var(--whiteColor);
        }
    }
    .single-process {
        text-align: center;
        margin-bottom: 30px;
        position: relative;
        .icon {
            background: #1e2a55;
            display: inline-block;
            width: 60px;
            height: 60px;
            line-height: 56px;
            margin-bottom: 30px;
            border-radius: 3px;
            position: relative;
        }
        .content {
            h3 {
                color: var(--whiteColor);
            }
            p {
                color: var(--whiteColor);
                margin-bottom: 0;
                font-size: 14px;
            }
        }
        span {
            position: absolute;
            z-index: 1;
            &::before {
                content: "";
                position: absolute;
                content: "";
                height: 170px;
                border-right: 1px dashed #f5faff;
                transform: rotate(90deg);
                top: -55px;
                left: 122px;
                z-index: -1;
            }
            img {
                max-width: 100px;
                margin-left: 100px;
                animation: leftani 5s infinite linear;
                @keyframes leftani {
                    0% {
                        transform: translateX(-15px);
                    }
                    50% {
                        transform: translateX(15px);
                    }
                    100% {
                        transform: translateX(-15px);
                    }
                }
            }
        }
    }
}

/*
Home Case Area Css
======================================================*/
.home-case {
    .case {
        position: relative;

        .nav-tabset {
            border-bottom: 1px solid var(--bodyColor);
            padding-bottom: 5px;
            margin-bottom: 40px;
            text-align: center;

            .nav-tab {
                display: inline-block;
                margin-right: 30px;
                margin-bottom: 6px;
                cursor: pointer;
                font-weight: 600;
                font-size: 15px;

                span {
                    color: #50607f;
                }
                &.active {
                    position: relative;

                    &::before {
                        position: absolute;
                        width: 100%;
                        height: 5px;
                        background: var(--mainColor);
                        content: "";
                        bottom: -14px;
                    }
                    span {
                        color: var(--mainColor);
                    }
                }
            }
        }
    }
    .case-list {
        .single-case {
            margin-bottom: 30px;
            transition: var(--transition);
            .case-img {
                img {
                    border-top-left-radius: 6px;
                    border-top-right-radius: 6px;
                }
            }
            .content {
                background: var(--whiteColor);
                box-shadow: var(--boxShadow);
                padding: 30px;
                border-bottom-left-radius: 6px;
                border-bottom-right-radius: 6px;
                h3 {
                    margin-bottom: 10px;
                    transition: var(--transition);
                }
                p {
                    margin-bottom: 12px;
                }
            }
            &:hover {
                transform: translateY(-3px);
                h3 {
                    color: var(--mainColor);
                }
            }
        }
    }
}

/*
Feature Area Css
======================================================*/
.feature-area {
    .feature-tittle {
        margin-bottom: 20px;
        span {
            color: var(--mainColor);
            display: inline-block;
            margin-top: -8px;
            margin-bottom: 8px;
        }
        h2 {
            font-size: 40px;
            margin-bottom: 15px;
        }
    }
    .contnet {
        ul {
            padding: 0;
            margin: 0;
            li {
                position: relative;
                padding-left: 30px;
                color: var(--darkBlueColor);
                font-size: 18px;
                margin-bottom: 10px;
                &:last-child {
                    margin-bottom: 0;
                }
                i {
                    position: absolute;
                    display: inline-block;
                    left: 0;
                    top: 0;
                    color: var(--mainColor);
                    font-size: 18px;
                }
            }
        }
        .box-btn {
            margin-top: 30px;
        }
    }
    .feature-img {
        animation: updown 5s infinite linear;
        @keyframes updown {
            0% {
                transform: translateY(-10px);
            }
            50% {
                transform: translateY(10px);
            }
            100% {
                transform: translateY(-10px);
            }
        }
    }
}




/*
Blog Area Css
======================================================*/
.home-blog-area {
    position: relative;
    .single-blog {
        background: var(--whiteColor);
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        position: relative;
        margin-bottom: 30px;
        overflow: hidden;
        transition: 0.5s;
        box-shadow: 2px 2px 20px 4px rgba(0, 0, 0, 0.07);
        z-index: 1;
        .content {
            ul {
                padding: 0;
                margin: 0;
                li {
                    font-size: 13px;
                    display: inline-block;
                    a {
                        position: relative;
                        margin-left: 25px;
                        &::before {
                            position: absolute;
                            content: "";
                            width: 2px;
                            height: 15px;
                            left: -16px;
                            top: 3px;
                            background: var(--bodyColor);
                        }
                    }
                }
            }
            padding: 30px;
            span {
                color: var(--headingColor);
                font-weight: 500;
            }
            h3 {
                margin-top: 10px;
                transition: var(--transition);
                margin-bottom: 0;
            }
            p {
                margin-top: 10px;
                transition: var(--transition);
            }
            .line-bnt {
                color: var(--darkBlueColor);
            }
        }
        &::after {
            position: absolute;
            content: "";
            background: var(--darkBlueColor);
            opacity: 0.8;
            bottom: 0;
            left: 100%;
            right: 0;
            width: 0;
            transition: 0.5s;
            z-index: -1;
            height: 100%;
        }
        &:hover {
            &::before {
                width: 100%;
                left: 0;
            }
            &::after {
                width: 100%;
                left: 0;
            }
            span,
            h3 {
                color: var(--whiteColor);
            }
            p {
                color: var(--whiteColor);
            }
            ul {
                li {
                    color: var(--whiteColor);
                    a {
                        color: var(--whiteColor);
                    }
                }
            }
            .line-bnt {
                color: var(--whiteColor);
            }
        }
    }
    .blog-btn {
        margin-top: 20px;
        p {
            font-size: 17px;
            color: var(--headingColor);
            margin-bottom: 0;
            a {
                color: var(--mainColor);
                text-decoration: underline;
            }
        }
    }
}

/*
Technology Area Css
======================================================*/
.technology-area {
    .technology-content {
        h2 {
            font-size: 40px;
            margin-bottom: 20px;
        }
        .box-btn {
            margin-top: 10px;
        }
    }
    .choose-img {
        position: relative;
        .technology-video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            display: flex;
        }
    }
}
.video-btn {
	display: inline-block;
	width: 70px;
	height: 70px;
	line-height: 70px;
	text-align: center;
	border-radius: 50%;
	color: var(--mainColor);
	position: relative;
	top: 3px;
	z-index: 1;
	background-color: var(--mainColor);
	i {
		font-size: 40px;
		font-weight: 700;
		color: var(--whiteColor);
		position: absolute;
		top: 0;
		left: 3px;
		justify-content: center;
		align-items: center;
		display: flex;
		width: 100%;
		height: 100%;
	}
	&::after, &::before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		z-index: -1;
		bottom: 0;
		left: 0;
		border-radius: 50%;
		background-color: var(--mainColor);
	}
	&::before {
		animation: ripple 1.6s ease-out infinite;
	}
	&::after {
		animation: ripple 1.6s ease-out infinite;
	}
	&:hover {
		background-color: var(--mainColor);
		i {
			color: var(--whiteColor);
		}
	}
}
@keyframes ripple {
	0%, 35% {
		transform: scale(0);
	  	opacity: 1;
	}
	50% {
		transform: scale(1.5);
	  	opacity: 0.8;
	}
	100% {
	  	opacity: 0;
		transform: scale(2);
	}
}

/*
About Area Css
======================================================*/
.about-area {
    position: relative;
    z-index: 1;
    overflow: hidden;
    .section-tittle {
        max-width: 850px;
    }
    img {
        border-radius: 10px;
    }
    .about-img {
        position: relative;
        margin-top: 40px;
        .video-btn {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            .video-pop {
                margin-top: 26px;
                font-size: 16px;
                color: var(--mainColor);
                .video {
                    display: inline-block;
                    width: 60px;
                    height: 60px;
                    line-height: 60px;
                    position: relative;
                    text-align: center;
                    border-radius: 50%;
                    color: var(--whiteColor);
                    background: var(--mainColor);
                    box-shadow: 0 0 20px 8px rgba(228, 221, 221, 0.363);
                    transition: 0.5s;
                    margin: 0 10px 0 10px;
                    i {
                        font-size: 40px;
                        position: relative;
                        top: 9px;
                        left: 3px;
                    }
                    &::before,
                    &::after {
                        animation: animatin 1.2s ease-out infinite;
                        content: '';
                        display: block;
                        position: absolute;
                        top: 0;
                        right: 0;
                        z-index: -1;
                        bottom: 0;
                        left: 0;
                        border-radius: 50%;
                        border: 10px solid var(--mainColor);
                    }
                    &::before {
                        animation: animatin 1.5s linear infinite;
                    }
                    &::after {
                        animation: animatin 2s linear 1s infinite;
                    }
                    @keyframes animatin {
                        0% {
                            transform: scale(1);
                        }
                        75% {
                            transform: scale(1.5);
                            opacity: 1;
                        }
                        100% {
                            transform: scale(2);
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }
    .content {
        margin-top: 50px;
        .about-content {
            p {
                color: #50607f;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

/*
Solutions Area Css
======================================================*/
.solution-img {
    margin-bottom: 30px;
}
.solutions-area {
    position: relative;
    z-index: 1;
    overflow: hidden;
    .section-tittle {
        max-width: 850px;
    }
    .single-solutions {
        position: relative;
        text-align: center;
        padding: 30px 20px;
        background: var(--whiteColor);
        margin-top: 30px;
        border-radius: 5px;
        z-index: 1;
        overflow: hidden;
        transition: all 0.5s;
        box-shadow: 2px 2px 20px 4px rgba(0, 0, 0, 0.07);
        .solutions-img {
            margin-bottom: 20px;
        }
        .solutions-content {
            h3 {
                color: var(--darkBlueColor);
                margin-bottom: 10px;
            }
            p {
                color: #50607f;
                margin-bottom: 10px;
            }
            .line-bnt {
                color: var(--mainColor);
                font-size: 14px;
            }
        }
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 100%;
            width: 100%;
            height: 100%;
            opacity: 0.8;
            background-color: var(--darkBlueColor);
            z-index: -1;
            transition: all 0.5s;
        }
        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            right: 100%;
            width: 100%;
            height: 100%;
            background: url(assets/images/service/hover-img.png);
            z-index: -2;
            transition: all 0.5s;
        }
        .solutions-back {
            position: absolute;
            transform: translateY(40px);
            padding-right: 20px;
        }
        &:hover {
            transform: translateY(-10px);
            h3 {
                color: var(--whiteColor);
                transform: translateY(-50px);
                opacity: 1;
            }
            p {
                color: #e9e9e9;
                opacity: 1;
                transform: translateY(-35px);
            }
            .line-bnt {
                color: #e6335a;
                text-decoration: underline;
                opacity: 1;
                transform: translateY(-25px);
            }
            .solutions-img {
                opacity: 0;
            }
            &::before {
                left: 0;
            }
            &::after {
                right: 0;
            }
        }
    }
}

/*
Facilities Area Css
======================================================*/
.facilities-area {
    padding-top: 60px;
    border-top: 1px solid #eeee;
    .mt-40 {
        margin-top: 40px;
    }
    position: relative;
    .section-tittle {
        max-width: 850px;
        p {
            color: #181818;
        }
    }
    .content {
        h3 {
            color: var(--headingColor);
            margin-bottom: 10px;
        }
        p {
            font-size: 16px;
            line-height: 1.9;
            color: #181818;
        }
        .box-btn {
            margin-top: 10px;
            padding: 15px 40px;
            border-radius: 50px;
        }
    }
}

/*
Blog Area Css
======================================================*/
.blog-area {
    position: relative;
    transition: 0.3s;
    overflow: hidden;
    .single-blog {
        background: var(--whiteColor);
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        position: relative;
        margin-top: 30px;
        overflow: hidden;
        transition: 0.5s;
        box-shadow: 2px 2px 20px 4px rgba(0, 0, 0, 0.07);
        z-index: 1;
        .content {
            ul {
                padding: 0;
                margin: 0;
                li {
                    font-size: 13px;
                    display: inline-block;
                    a {
                        position: relative;
                        margin-left: 25px;
                        &::before {
                            position: absolute;
                            content: "";
                            width: 2px;
                            height: 15px;
                            left: -16px;
                            top: 2px;
                            background: var(--darkBlueColor);
                        }
                    }
                }
            }
            padding: 30px;
            span {
                color: var(--headingColor);
                font-weight: 500;
            }
            h3 {
                color: var(--darkBlueColor);
                margin-top: 10px;
            }
            p {
                margin-top: 10px;
                color: #50607f;
            }
            .line-bnt {
                color: var(--darkBlueColor);
            }
        }
        &::after {
            position: absolute;
            content: "";
            background: var(--darkBlueColor);
            opacity: 0.8;
            bottom: 0;
            left: 100%;
            right: 0;
            width: 0;
            transition: 0.5s;
            z-index: -1;
            height: 100%;
        }
        &:hover {
            &::before {
                width: 100%;
                left: 0;
            }
            &::after {
                width: 100%;
                left: 0;
            }
            span,
            h3 {
                color: #f5ebff;
            }
            p {
                color: var(--whiteColor);
            }
            ul {
                li {
                    color: #cbcbcb;
                }
            }
        }
    }
}
/*
Testimonials Area Css
======================================================*/
.testimonials-area {
    .section-tittle {
        margin-bottom: 50px;
        p {
            margin: 0 15%;
        }
    }
    .single-testimonials {
        box-shadow: var(--boxShadow);
        padding: 20px 20px;
        position: relative;
        text-align: left;
        overflow: hidden;
        margin-bottom: 20px;
        transition: var(--transition);
        .testimonials-head {
            .testimonials-img {
                border: 1px solid var(--mainColor);
                padding: 6px;
                overflow: hidden;
            }
            .content {
                margin-top: 0;
                position: relative;
                top: 2px;
                h2 {
                    color: #181818;
                    font-family: "Poppins", sans-serif;
                    position: relative;
                    font-size: 21px;
                    margin-bottom: 5px;
                }
                .rate {
                    padding: 0;
                    margin: 0;
                    li {
                        display: inline-block;
                        i {
                            font-size: 15px;
                            color: #ffc107;
                        }
                    }
                }
                span {
                    font-size: 15px;
                }
            }
        }
        .testimonials-foot {
            p {
                margin-bottom: 0;
                color: #616161;
            }
        }
        &:hover {
            transform: translateY(-5px);
        }
        hr {
            margin-top: 18px !important;
            margin-bottom: 8px !important;
        }
    }
}


 
/*
Contact Area Css
======================================================*/
.home-contact-area {
    .contact-img {
        animation: updown 5s infinite linear;
        @keyframes updown {
            0% {
                transform: translateY(-10px);
            }
            50% {
                transform: translateY(10px);
            }
            100% {
                transform: translateY(-10px);
            }
        }
    }
    .content {
        box-shadow: var(--boxShadow);
        background-color: var(--whiteColor);
        border-radius: var(--borderRadius);
        padding: 30px;
        input.form-control {
            height: 55px;
            border: 1px solid #eeeeee;
            margin-bottom: 20px;
            padding-left: 25px;
            background-color: #f9f9f9;
            &:focus {
                box-shadow: none;
                border-color: var(--mainColor);
            }
        }
        textarea.form-control {
            width: 100%;
            border: 1px solid #eeeeee;
            max-height: 170px;
            min-height: 170px;
            box-shadow: none;
            padding-left: 25px;
            padding-top: 15px;
            margin-bottom: 20px;
            background-color: #f9f9f9;
            &:focus {
                box-shadow: none;
                border-color: var(--mainColor);
            }
        }
        button {
            border: none;
            padding: 15px 35px;
            display: table;
            text-align: center;
            margin: 0 auto -8px;
        }
    }
    .list-unstyled {
        color: #dc3545;
        margin-top: -13px;
        margin-bottom: -8px;
        font-size: 13px;
    }
    .text-danger {
        color: #dc3545!important;
        margin-top: 15px;
    }
    .text-success {
        color: green!important;
    }
    #msgSubmit {
        text-align: center;
    }
}
.home-2-contact {
    .content {
        box-shadow: var(--boxShadow);
        padding: 30px;
        border-radius: var(--borderRadius);
        input.form-control {
            border-radius: var(--borderRadius);
        }
        textarea.form-control {
            border-radius: 4px;
        }
    }
    .contact-img {
        background: url(assets/images/contact-img2.jpg);
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 100%;
        position: relative;
        animation: none;
        img {
            display: none;
        }
    }
}
.contact-area {
	.single-contact {
		text-align: center;
		position: relative;
		box-shadow: var(--boxShadow);
		padding: 30px;
		border-radius: 10px;
		transition: var(--transition);
		margin-bottom: 30px;
		.icon {
			width: 45px;
			height: 45px;
			background-color: var(--mainColor);
			color: var(--whiteColor);
			-webkit-transition: 0.3s;
			transition: 0.3s;
			border-radius: 50%;
			margin: 0 auto;
			margin-bottom: 20px;
			i {
				font-size: 23px;
				line-height: 45px;
			}
        }
        &:hover {
            transform: translateY(-5px);
        }
	}
}

/*
Footer Area Css
======================================================*/
.footer-area {
    position: relative;
    overflow: hidden;
    // background: url(assets/images/footer-bg.png) no-repeat;
    background-size: cover;
    background-position: center;
    background-color:  #123726;;
    z-index: 1;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color:  #123726;
        z-index: -1;
        // background-image: url(assets/images/footer-bg1.png);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        animation: imageChange 1s linear 1s infinite;

        @keyframes imageChange {
            0% {
                opacity: 1;
            }
            75% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }
    }
    .newsletter-form {
        margin-bottom: 25px;
    }
    .content {
        margin-bottom: 30px;

        p {
            color: var(--whiteColor);
            margin-top: 20px;
        }
        .subscribe {
            position: relative;
            max-width: 100%;

            h4 {
                font-size: 24px;
                color: #f5faff;
            }
            input#emails {
                position: relative;
                height: 50px;
                padding-left: 20px;
                border-radius: var(--borderRadius);
                color: var(--whiteColor);

                &::placeholder {
                    color: var(--whiteColor);
                }
                &:focus {
                    box-shadow: none;
                }
                margin: 20px 0;
                margin-bottom: 10px;
            }
            .newsletter-form {
                position: relative;
            }
            button {
                position: absolute;
                top: 0;
                right: 0;
                border: none;
                height: 50px;

                &::before {
                    background-color: var(--whiteColor);
                }
                &::after {
                    background-color: var(--whiteColor);
                }
                &:hover {
                    background-color: var(--mainColor);
                    color: var(--mainColor);
                }
            }
            #validator-newsletter {
                display: inline-block;
                color: #e6335a;
            }
        }
        .social {
            margin: 0;
            padding: 0;
            li {
                display: inline-block;
                margin-right: 10px;
                a {
                    width: 35px;
                    height: 35px;
                    background: var(--mainColor);
                    border-radius: 5px;
                    line-height: 40px;
                    text-align: center;
                    transition: 0.3s;
                    color: #f5faff;
                    transition: 0.3s;
                    i {
                        font-size: 18px;
                    }
                    &:hover {
                        transform: translateY(-3px);
                    }
                }
            }
        }
        h3 {
            font-size: 25px;
            color: #f5faff;
            margin-bottom: 25px;
            position: relative;
            z-index: 1;
            &::before {
                position: absolute;
                content: "";
                width: 3px;
                height: 100%;
                background: #C3A35Dd9;
                left: -15px;
                z-index: -1;
            }
            &::after {
                position: absolute;
                content: '';
                background: var(--darkBlueColor);
                width: 7px;
                height: 7px;
                left: -15px;
                top: 0;
                right: 0;
                overflow: hidden;
                animation: line 8s infinite;
                @keyframes line {
                    0% {
                        transform: translateY(0);
                    }
                    50% {
                        transform: translateY(30px);
                    }
                    100% {
                        transform: translateY(0);
                    }
                }
            }
        }
        .footer-list {
            margin: 0;
            padding: 0;
            li {
                line-height: 1.8;
                margin-bottom: 8px;
                position: relative;
                a {
                    color: #f5faff;
                    font-size: 15px;
                    transition: 0.3s;
                    i {
                        position: absolute;
                        top: 4px;
                        left: 0;
                        color: var(--whiteColor);
                        display: inline-block;
                        font-size: 15px;
                    }
                    &:hover {
                        letter-spacing: 0.02em;
                    }
                }
            }
        }
        .foot-social {
            position: relative;
            li {
                position: relative;
                padding-left: 48px;
                color: #f5faff;
                font-size: 16px;
                margin-bottom: 20px;
                i {
                    position: absolute;
                    display: inline-block;
                    left: 0;
                    top: 4px;
                    width: 30px;
                    height: 30px;
                    background: var(--mainColor);
                    border-radius: 5px;
                    line-height: 31px;
                    text-align: center;
                    transition: 0.3s;
                    color: #f5faff;
                }
            }
        }
    }
    .copy-area {
        margin-top: 70px;
        padding: 20px 0;
        border-top: 1px solid #2a355e;
        .menu {
            margin: 0;
            padding: 0;
            li {
                display: inline-block;
                margin-right: 25px;
                font-size: 15px;
                a {
                    color: #f5faff;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        p {
            font-size: 15px;
            color: #f5faff;
            margin-bottom: 0;
            a {
                color: rgb(214, 186, 83);
                font-size: 16px;
                font-weight: 600;
                &:hover {
                    color: var(--bodyColor);
                }
            }
        }
        .right {
            float: right;
        }
    }
}
.footer-area-2 {
    // background: var(--darkBlueColor);
    position: relative;
    z-index: 1;
    &::before {
        display: none;
    }
}

/* 
Page Title Area Css
=====================================================*/
.page-title-area {
	position: relative;
	overflow: hidden;
	padding-top: 220px;
	padding-bottom: 140px;
	text-align: center;
    background-color: var(--darkBlueColor);
	height: 100%;
	z-index: 1;
	.page-title-content {
		h2 {
			margin-bottom: 15px;
			color: var(--whiteColor);
			font-size: 40px;
			font-weight: 700;
		}
		ul {
			padding-left: 0;
			list-style-type: none;
			margin-top: 10px;
			margin-bottom: 0;
			li {
				display: inline-block;
				position: relative;
				font-size: 15px;
				font-weight: 500;
				padding-right: 20px;
				padding-left: 20px;
				color: var(--whiteColor);
				&::before {
					content: "";
					position: absolute;
					top: 10px;
					right: -4px;
					background-color: #70a1ff;
					width: 8px;
					height: 8px;
				}
				&:last-child {
					&::before {
						display: none;
					}
				}
				a {
					color: var(--whiteColor);
					&:hover {
						color: var(--mainColor);
					}
				}
			}
			.active {
				color: #70a1ff;
			}
		}
	}	
	.page-shape {
        .shape1 {
            position: absolute;
            top: 55%;
            left: 5%;
            animation: animationFramesOne 20s linear infinite;
        }
        .shape6 {
            position: absolute;
            bottom: 27%;
            right: 22%;
            animation: animationFramesOne 15s linear infinite;
        }
        .shape3 {
            position: absolute;
            left: 25%;
            bottom: 15%;
            animation: rotaed360 6s infinite linear;
        }
        .shape4 {
            position: absolute;
            top: 27%;
            left: 48%;
            animation: rotaed360 6s infinite linear;
        }
        .shape5 {
            position: absolute;
            top: 15%;
            right: 3%;
            animation: rotaed360 6s infinite linear;
        }
    }
}

/* 
Pagenavigation Area Css
=====================================================*/
.page-navigation-area {
	text-align: center;
	margin: 20px auto 0;
	display: table;
	.page-link {
		background-color: var(--mainColor);
		box-shadow: var(--boxShadow);
		width: 40px;
		height: 40px;
		line-height: 24px;
		transition: var(--transition);
        font-weight: 700;
        color: var(--whiteColor) !important;
        line-height: 23px;
        border-radius: 50%;
		i {
			margin-right: -4px;
            font-size: 21px;
		}
		&:hover {
			color: var(--whiteColor);
			background-color: var(--darkBlueColor);
		}
		&:focus {
			z-index: 2;
			outline: 0;
			box-shadow: none;
		}
	}
	.page-link.page-links {
		i {
			&::before {
				margin-left: -4px;
			}
		}
	}
	.page-item {
		padding: 0 8px 0;
		&:first-child {
			.page-link {
				border-radius: 50%;
			}
		}
		&:last-child {
			.page-link {
				border-radius: 50%;
			}
		}
	}
	.page-item.active {
		.page-link {
			z-index: 1;
			color: var(--whiteColor);
			background-color: var(--darkBlueColor);
		}
	}
}

/*
Project Area Six Style
======================================================*/ 
.project-area-six {
	text-align: center;
	.single-project {
		position: relative;
		overflow: hidden;
		transition: all 0.5s;
		margin-bottom: 30px;
		.project-image {
			position: relative;
			transition: all 0.5s;
			height: 380px;
			background-position: center center;
			background-size: cover;
			background-repeat: no-repeat;
			&.bg-1 {
				background-image: url(assets/images/gallery/1.jpg);
			}
			&.bg-2 {
				background-image: url(assets/images/gallery/2.jpg);
			}
			&.bg-3 {
				background-image: url(assets/images/gallery/3.jpg);
			}
			&.bg-4 {
				background-image: url(assets/images/gallery/4.jpg);
			}
			&.bg-5 {
				background-image: url(assets/images/gallery/5.jpg);
			}
			img {
				transition: all 9s;
			}
		}
		.project-content {
			position: absolute; 
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
			padding: 15px;
			transition: var(--transition);
			z-index: 2;
			text-align: center;
			width: 93%;
			height: 90%;
			justify-content: center;
			align-items: center;
			display: flex;
			background-color: rgba(37, 36, 94, 0.9);
			transform: scaleY(0);
			span {
				color: var(--whiteColor);
				font-weight: 500;
				display: block;
				margin-bottom: 6px;
			}
			h3 {
				margin-bottom: 4px;
				transition: var(--transition);
				&:hover {
					a {
						text-decoration: underline;
					}
						
				}
				a {
					color: var(--whiteColor);
				}
				&:hover {
					a {
						color: var(--whiteColor);
					}
						
				}
			}
			.read-more {
				&:hover {
					color: var(--whiteColor);
				}
			}
		}
		&:hover {
			.project-content { 
				transform: scaleY(1);
			}
			.project-image {
				transform: scale(2.2) rotate(4deg);
			}
		}
	}
}


